<template>
  <v-card>
    <v-card-text>
      <v-stepper v-model="step" alt-labels>
        <v-stepper-header>
          <v-stepper-step :complete="step > 1" step="1" :editable="!ordering">
            {{ $t("availability") }}
          </v-stepper-step>
          <v-divider></v-divider>

          <v-stepper-step :complete="step > 2" step="2">
            {{ $t("network") }}
          </v-stepper-step>
          <v-divider></v-divider>

          <v-stepper-step :complete="step > 3" step="3">
            {{ $t("profile") }}
          </v-stepper-step>
          <v-divider></v-divider>

          <v-stepper-step step="4">
            {{ $t("options") }}
          </v-stepper-step>
        </v-stepper-header>

        <v-stepper-items>
          <v-stepper-content step="1">
            <v-card elevation="0" color="transparent">
              <validation-observer
                v-slot="{ invalid }"
                ref="opsStep1"
                :disabled="step !== 1"
              >
                <v-card-text class="mb-12">
                  <v-row>
                    <v-col cols="12">
                      <checker-query :checking="checking" />
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="primary"
                    @click="step1"
                    :disabled="
                      invalid ||
                      checking ||
                      $store.getters.isReadOnly ||
                      checkIdx == null ||
                      checkIdx == 4
                    "
                  >
                    {{ $t("check") }}
                  </v-btn>
                </v-card-actions>
              </validation-observer>
            </v-card>
          </v-stepper-content>

          <v-stepper-content step="2">
            <v-card elevation="0" color="transparent">
              <validation-observer
                v-slot="{ invalid }"
                ref="opsStep2"
                :disabled="step !== 2"
              >
                <v-card-text class="mb-12">
                  <checker-results :checking="checking" />
                </v-card-text>
                <v-card-actions>
                  <v-btn @click="resetOrder" text>
                    {{ $t("cancel") }}
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn @click="step = step - 1" text>
                    {{ $t("back") }}
                  </v-btn>
                  <v-btn
                    color="primary"
                    @click="step2"
                    :disabled="
                      invalid ||
                      checking ||
                      service == null ||
                      (selectOto == null && otos.length > 0) ||
                      $store.getters.isReadOnly
                    "
                  >
                    {{ $t("continue") }}
                  </v-btn>
                </v-card-actions>
              </validation-observer>
            </v-card>
          </v-stepper-content>

          <v-stepper-content step="3">
            <v-card elevation="0" color="transparent">
              <validation-observer
                v-slot="{ invalid }"
                ref="opsStep3"
                :disabled="step !== 3"
              >
                <v-card-text class="mb-12" v-if="step == 3">
                  <profiles-bbcs
                    v-if="isBbcs"
                    :checking="checking"
                    observer-name="opsStep3"
                    :fire="fireBbcsQuery"
                    @fired="fireBbcsQuery = false"
                  />
                  <profiles-metronet v-else />
                </v-card-text>
                <v-card-actions>
                  <v-btn @click="resetOrder" text>
                    {{ $t("cancel") }}
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn @click="step = step - 1" text>
                    {{ $t("back") }}
                  </v-btn>
                  <v-btn
                    color="primary"
                    @click="step3"
                    :disabled="
                      invalid ||
                      checking ||
                      profile == null ||
                      $store.getters.isReadOnly ||
                      !bbcsResultStateOk
                    "
                  >
                    {{ $t("continue") }}
                  </v-btn>
                </v-card-actions>
              </validation-observer>
            </v-card>
          </v-stepper-content>

          <v-stepper-content step="4">
            <v-card elevation="0" color="transparent">
              <validation-observer
                v-slot="{ invalid }"
                ref="opsStep4"
                :disabled="step !== 4"
              >
                <v-card-text class="mb-12">
                  <order-summary class="mb-4" />
                  <create-order />
                </v-card-text>
                <v-card-actions>
                  <v-btn @click="resetOrder" text>
                    {{ $t("cancel") }}
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn @click="step = step - 1" :disabled="ordering">
                    {{ $t("back") }}
                  </v-btn>
                  <v-btn
                    color="primary"
                    @click="step4"
                    :disabled="
                      invalid ||
                      ordering ||
                      $store.getters.isReadOnly ||
                      service == null
                    "
                    :loading="ordering"
                  >
                    {{ $t("order") }}
                  </v-btn>
                </v-card-actions>
              </validation-observer>
            </v-card>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </v-card-text>
  </v-card>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import CheckerQuery from "@/components/orders/internet/CheckerQuery";
import CheckerResults from "@/components/orders/internet/CheckerResults";
import ProfilesBbcs from "@/components/orders/internet/ProfilesBbcs";
import ProfilesMetronet from "@/components/orders/internet/ProfilesMetronet";
import CreateOrder from "@/components/orders/internet/CreateOrder";
import OrderSummary from "@/components/orders/internet/OrderSummary";
import DatePicker from "@/components/basics/DatePicker";
import convertCheckerResults from "@/utils/convert/convertCheckerResults";
import convertBbcsQualiResults from "@/utils/convert/convertBbcsQualiResults";
import sortCheckerResults from "@/utils/mixins/sortCheckerResults";
import showErrors from "@/utils/mixins/showErrors";

const { mapState, mapGetters, mapMutations, mapActions } =
  createNamespacedHelpers("inetOrder");

export default {
  name: "InternetShop",
  components: {
    CheckerQuery,
    CheckerResults,
    ProfilesBbcs,
    ProfilesMetronet,
    CreateOrder,
    OrderSummary,
    DatePicker,
  },
  mixins: [sortCheckerResults, showErrors],
  data: () => ({
    checking: false,
    ordering: false,
    fireBbcsQuery: false,
    step: 1,
  }),
  computed: {
    ...mapState([
      "checkIdx",
      "address",
      "number",
      "oto",
      "plug",
      "bindingId",
      "bbType",
      "sessionType",
      "options",
      "orderEmail",
      "orderNote",
      "orderContact",
      "predecessorInfo",
      "productDescr",
      "bbcsRadiusPassword",
      "ispChange",
      "pool",
      "streaming",
      "slaEmail",
      "slaMobile",
      "wishDate",
      "ripeNetname",
    ]),
    ...mapGetters([
      "checkBy",
      "result",
      "service",
      "profile",
      "dnType",
      "otos",
      "bbcsUsernameWithSuffix",
      "bbcsResultStateOk",
      "timeSlot",
      "installAddress",
      "contactNeeded",
    ]),
    ...mapGetters({ selectOto: "oto" }),
    isBbcs: {
      ...mapState({ get: "isBbcs" }),
      ...mapMutations({ set: "setIsBbcs" }),
    },
    bbcsProperties: {
      ...mapState({ get: "bbcsProperties" }),
      ...mapActions({ set: "setBbcsProperties" }),
    },
  },
  watch: {
    "$store.state.session": {
      immediate: true,
      handler(value) {
        this.readBbcsProperties();
      },
    },
    step: {
      immediate: true,
      handler(value) {
        if (value == 1) {
          this.resetOrderStore();
          this.isBbcs = false;
          this.resetCheckResultsUnselected([]);
        } else if (value == 4) this.$refs.opsStep4.validate();
        if (this.checkBy == "BINDING") this.isBbcs = true;
      },
    },
  },
  methods: {
    ...mapActions([
      "resetCheckResults",
      "resetCheckResultsUnselected",
      "resetOrderContact",
      "resetPredecessorInfo",
      "resetOrderStore",
    ]),
    resetOrder() {
      this.step = 1;
    },
    checkerQuery() {
      var that = this;
      this.checking = true;
      var params = {};
      if (this.checkBy == "ADDRESS")
        params = {
          ...params,
          zip: this.address.zip || null,
          city: this.address.city || null,
          street: this.address.street || null,
          house_nr: this.address.street_number || null,
          building: this.address.building || null,
        };
      else if (this.checkBy == "NUMBER")
        params = {
          ...params,
          number: this.number,
        };
      else if (this.checkBy == "OTO")
        params = {
          ...params,
          oto: this.oto,
          plug: this.plug, // checker does not support "plug" anymore
        };
      this.$http
        .get("services/internet/check", { params: params })
        .then((response) => {
          that.resetCheckResultsUnselected(
            this.sortCheckerResults(convertCheckerResults(response.data)),
            false
          );
        })
        .catch((error) => {
          if (error.failed())
            that.$store.commit("setSystemError", {
              msg: that.$i18n.t("serverError", { error: error.message }),
              title: error.title,
            });
          else
            that.$store.commit("setSystemError", {
              msg: error.message,
              title: error.title,
            });
        })
        .finally(function () {
          that.checking = false;
          that.step = 2;
        });
    },
    bbcsQuery() {
      var that = this;
      this.checking = true;
      var params = {
        dntype: this.dnType,
        bbtype: this.bbType || "ADSL,VDSL",
        sessiontype: this.sessionType,
      };
      if (this.checkBy == "OTO") {
        params.quali_by = "SOCKET";
        params.oto = this.oto;
        params.plug = this.plug || null;
      } else if (this.checkBy == "ADDRESS") {
        params.quali_by = "ADDRESS";
        params.first_name = this.address.first_name || null;
        params.last_name = this.address.last_name || null;
        params.zip = this.address.zip || null;
        params.city = this.address.city || null;
        params.street = this.address.street || null;
        params.house_number = this.address.street_number || null;
        params.building = this.address.building || null;
      } else if (this.checkBy == "NUMBER") {
        params.quali_by = "DN";
        params.dn = this.number || null;
      } else if (this.checkBy == "BINDING") {
        params.quali_by = "BINDING";
        params.bindingid = this.bindingId;
      }
      this.$http
        .post("services/bbcs/quali", params)
        .then((response) => {
          that.resetCheckResultsUnselected(
            convertBbcsQualiResults(
              response.data,
              params.bbtype == "FTTH" || params.bbtype == "BX"
                ? "bbcs_fiber"
                : params.bbtype == "XGS"
                ? "bbcs_xgspon"
                : "bbcs"
            ),
            false
          );
        })
        .catch((error) => {
          if (error.invalid()) {
            that.$refs.opsStep1.setErrors(error.data);
            that.showErrors(that.$refs.opsStep1.getUnresolvedErrors(error));
          } else
            that.$store.commit("setSystemError", {
              msg: error.message,
              title: error.title,
            });
        })
        .finally(function () {
          that.checking = false;
          that.step = 2;
        });
    },
    readBbcsProperties() {
      var that = this;
      this.$http
        .get("services/bbcs/properties")
        .then((response) => {
          this.bbcsProperties = { value: response.data };
        })
        .catch((error) => {
          that.$store.commit("setSystemError", {
            msg: error.message,
            title: error.title,
          });
        });
    },
    order() {
      var that = this;
      this.ordering = true;
      var url = null;
      var params = {
        ...this.predecessorInfo,
        options: this.options.map(function (opt) {
          return {
            id: opt.id,
            quantity: opt.disabled ? 0 : opt.quantity,
          };
        }),
        variant_id:
          this.profile.gfo_variant != null ? this.profile.gfo_variant.id : null,
        email: this.orderEmail,
        note: this.orderNote || null,
        sla_email: this.slaEmail,
        sla_mobile: this.slaMobile,
        ripe_netname: this.ripeNetname,
        product_description: this.productDescr || null,
      };

      // add install contact if needed
      if (this.contactNeeded) {
        params.contact = this.orderContact;

        /* fix zip
         * TODO: this backend
         */
        params.contact.zip = params.contact.zip || null;

        /* fix language
         * TODO: this backend
         */
        params.contact.language =
          params.contact.language != null
            ? params.contact.language.toUpperCase()
            : "DE";
      }

      if (this.isBbcs)
        params = {
          ...params,
          radius_username:
            this.sessionType == "PPP"
              ? this.bbcsUsernameWithSuffix || null
              : null,
          radius_password:
            this.sessionType == "PPP" ? this.bbcsRadiusPassword || null : null,
          ordertype: this.ispChange ? "ISPCHANGE" : "CREATE",
          sessiontype: this.sessionType,
          pool: this.pool,
          streaming: this.streaming,
          quali_id: this.service.quali_id,
          quali_profile_id: this.profile.quali_profile_id,
          quali_timeslot_id:
            this.timeSlot != null ? this.timeSlot.quali_timeslot_id : null,
        };
      else
        params = {
          ...params,
          network_id: this.profile.gfo_network.id,
          comment: null,
          oto_id: this.oto + (this.plug ? "." + this.plug : ""),
          wishdate: this.wishDate,
          address: this.installAddress,
        };
      url = this.isBbcs ? "services/bbcs" : "services/metronet";
      this.$http
        .post(url, params)
        .then((response) => {
          that.$snotify.success(
            that.$i18n.t(
              "Your {worker} {subject} has been successfully commited.",
              {
                worker: response.data.worker_name,
                subject: response.data.subject,
              }
            )
          );
          // goto tasks and open new worker
          that.$router.push({
            name: "tasks",
            params: { selectedTaskId: response.data.id },
          });
          that.resetOrder();
        })
        .catch((error) => {
          if (error.invalid()) {
            that.$refs.opsStep4.setErrors(error.data);
            that.showErrors(that.$refs.opsStep4.getUnresolvedErrors(error));
          } else
            that.$store.commit("setSystemError", {
              msg: error.message,
              title: error.title,
            });
        })
        .finally(function () {
          that.ordering = false;
        });
    },
    step1: function () {
      if (this.checkBy == "BINDING") {
        this.isBbcs = true;
        this.bbcsQuery();
      } else {
        this.isBbcs = false;
        this.checkerQuery();
      }
    },
    step2: function () {
      this.fireBbcsQuery = true;
      this.step = 3;
    },
    step3: function () {
      this.step = 4;
    },
    step4: function () {
      this.order();
    },
  },
};
</script>

<style scoped>
.v-application .mt-15 {
  margin-top: 120px !important;
}
</style>

<i18n>
{
  "en": {
    "if not set, order will be finshied asap.": "If not set, order will be finshied asap.",
    "new loop": "New loop",
    "availability": "Availability",
    "check": "Check",
    "profile": "Profile",
    "network": "Network",
    "OTO ID": "OTO ID",
    "options": "Options",
    "order": "Order",
    "back": "Back",
    "continue": "Continue",
    "all": "all",
    "active": "active",
    "inactive": "inactive",
    "planned": "planned",
    "line state": "Line state",
    "Your {worker} {subject} has been successfully commited.": "Your {worker} {subject} has been successfully commited.",
    "serverError": "There was a problem, please try again later.<br/><p>{error}</p>"
  }, 
  "de": {
    "if not set, order will be finshied asap.": "wenn nicht gesetzt, wird die Bestellung so schnell wie möglich ausgeführt",
    "new loop": "New loop",
    "availability": "Verfügbarkeit",
    "check": "prüfen",
    "profile": "Profil",
    "network": "Netz",
    "OTO ID": "OTO ID",
    "options": "Optionen",
    "order": "bestellen",
    "back": "zurück",
    "continue": "weiter",
    "all": "alle",
    "active": "aktiv",
    "inactive": "inaktiv",
    "planned": "geplant",
    "line state": "Leitungsstatus",
    "Your {worker} {subject} has been successfully commited.": "Ihre {worker} {subject} wurde erfolgreich übermittelt.",
    "serverError": "Es ist ein Problem aufgetreten, bitte versuchen Sie es später erneut.<br/><p>{error}</p>"
  }
}
</i18n>
